@use "./breakpoints";

@mixin hoverable($width: null, $height: $width) {
  @if ($width) {
    margin: (-$width) (-$height);
    padding: $width $height;
  }
  transition: 0.1s ease-out;
  transition-property: color, background-color, box-shadow;

  @media (hover: hover) {
    &:hover {
      background-color: var(--background-hover);
    }
  }  
}

@mixin stickySidebar {
  @include breakpoints.at-least("lg") {
    position: sticky;
    top: var(--v-page-spacing);
    max-height: calc(100vh - var(--v-page-spacing) - 24px);
    overflow-y: auto;
  }
}