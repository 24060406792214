@use "@sequoiacap/client-ui/styles/breakpoints";

.activityList {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include breakpoints.at-most("md") {
    --text-normal: var(--text-normal-inverted);
    --text-subtle: var(--text-subtle-inverted);
  }
}

.loadingCell {
  border-radius: 4px;
  margin: -8px;
  padding: 8px;
}

.noNotificationsCell {
  padding: 16px;
  text-align: center;
}

.activityCell {
  margin: -6px -18px;
  padding: 8px 36px 8px 16px;
}

.moreActivityLink {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  gap: 4px;
  margin: -8px;
  margin-left: auto;
  padding: 8px;
  transition: background-color 0.1s ease-out;

  &:hover {
    background-color: var(--background-hover);
  }
}
