@use "sass:list";
@use "sass:map";
@use "sass:meta";


$start-points: (
  "sm": 0,
  "md": 540px,
  "lg": 948px,
  "xl": 1344px,
);

@mixin at-least($breakpoint) {
  @if map.has-key($start-points, $breakpoint) {
    @media (min-width: #{meta.inspect(start-point($breakpoint))}) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Available breakpoints are: #{map.keys($breakpoints)}.";
  }
}

@mixin at-most($breakpoint) {
  @if map.has-key($start-points, $breakpoint) {
    @media (max-width: #{meta.inspect(end-point($breakpoint))}) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Available breakpoints are: #{map.keys($start-points)}.";
  }
}

@mixin at($breakpoint) {
  @if map.has-key($start-points, $breakpoint) {
    @if $breakpoint == "xl" {
      @include at-least($breakpoint) {
        @content;
      }
    } @else {
      @media (min-width: #{meta.inspect(start-point($breakpoint))}) and (max-width: #{meta.inspect(end-point($breakpoint))}) {
        @content;
      }
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Available breakpoints are: #{map.keys($start-points)}.";
  }
}

@function start-point($breakpoint) {
  @return meta.inspect(map.get($start-points, $breakpoint));
}

@function end-point($breakpoint) {
  @if $breakpoint == "xl" {
    @return 0 * 1px;
  }
  @return list.nth(
      list.nth(
        $start-points,
        list.index(map.keys($start-points), $breakpoint) + 1
      ),
      2
    ) - 1;
}
