/* stylelint-disable no-descending-specificity */
@use "@sequoiacap/client-ui/styles/breakpoints";
@use "@sequoiacap/client-ui/styles/tokens";

.burgerButtonContainer {
  align-items: center;
  display: flex;
  gap: 4px;
  transform: translateX(-13px);
}

.menuModalHeader {
  align-items: center;
  border-bottom: 1px solid var(--border-light);
  display: flex;
  gap: 4px;
  height: 56px;
  padding-left: var(--h-page-spacing);

  > * {
    transform: translateX(-16px);
  }
}

.content {
  @include breakpoints.at-most("md") {
    transform: translateX(8px);
  }
}

.menuProfileModal.menuProfileModal.menuProfileModal {
  background-color: tokens.$color-s-dark-green;
  color: var(--text-normal-inverted);
  max-width: 260px;

  @include breakpoints.at-least("md") {
    width: 280px;
  }
}

.menuModal {
  .navItem {
    border-bottom: 1px solid var(--border-light);
    padding: 16px var(--h-page-spacing);
  }
}

.menuItems {
  width: 290px;
}

.notificationDot {
  background: tokens.$color-s-green;
  border-radius: 12px;
  color: tokens.$color-s-white;
  display: grid;
  height: 20px;
  min-width: 20px;
  padding: 0 4px;
  place-items: center;
  position: absolute;
  transform: translate(50%, -50%);
}

.profileContent {
  display: flex;
  flex-direction: column;
  padding: 16px 18px;

  @include breakpoints.at-least("lg") {
    width: 290px;
  }
}

.profileContentHeader {
  align-items: center;
  display: flex;
  gap: 8px;

  @include breakpoints.at-least("lg") {
    display: none;
  }
}

.profileContentNotificationsSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  @include breakpoints.at-least("lg") {
    margin-top: 0;
  }
}

.profileContentNotificationsSectionHeader {
  align-items: center;
  display: flex;
  gap: 8px;
}

.profileContentSection {
  border-top: 1px solid tokens.$color-s-green;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
  padding-top: 20px;

  @include breakpoints.at-least("lg") {
    border-color: var(--border-light);
  }
}

.profileContentSectionHeader {
  margin-top: -16px;
}

.profileContentSectionLink {
  align-items: center;
  display: flex;
  gap: 8px;
  margin: -8px -18px;
  padding: 8px 16px;
  transition: background-color 0.1s ease-out;

  &:hover {
    background-color: var(--background-hover);
  }
}

.invertColors {
  --text-normal: var(--text-normal-inverted);

  color: var(--text-normal);
}

.logo {
  padding-right: 20px;
  display: flex;
  align-items: center;  
  height: 100%;
  fill: var(--text-normal);
}

@media print {
  .logo,
  .content,
  .container > *,
  .burgerButtonContainer {
    display: none;
  }
}
