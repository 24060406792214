@use "@sequoiacap/client-ui/styles/tokens";

.activityCell {
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  padding: 16px 44px 16px 16px;
  position: relative;
  transition: background-color 0.1s ease-out;

  &:hover {
    background-color: var(--background-hover);
  }
}

.senderName,
.title {
  color: var(--text-normal);
}

.unreadIndicator {
  background-color: tokens.$color-s-green;
  border-radius: 50%;
  height: 12px;
  position: absolute;
  right: 16px;
  top: 24px;
  width: 12px;
}
