@use "@sequoiacap/client-ui/styles/breakpoints";

/**
* The container for the majority of the content, under the search.
*/
.mainContainer {
  box-sizing: content-box;
  margin: 0 auto;
  max-width: var(--page-max-width);
  min-width: var(--page-min-width);
  padding: var(--v-page-spacing) var(--h-page-spacing);

  &.noPadding {
    padding: 0;
  }

  &.noMaxWidth {
    max-width: none;
  }
}

:has(.fillSpace) {
  display: flex;
  flex-direction: column;
  gap: 0;
  height: 100dvh;
  overflow: hidden;
  width: 100%;

  .fillSpace {
    flex-grow: 1;
    width: 100%;
  }
}
