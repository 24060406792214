@use "@sequoiacap/client-ui/styles/tokens";

.container {
  position: relative;
}

.notificationDot {
  background-color: tokens.$color-s-green;
  border: 2px solid var(--page-bg);
  border-radius: 50%;
  content: "";
  display: block;
  height: 12px;
  opacity: 0;
  position: absolute;
  right: 8px;
  top: 8px;
  transform: scale(0);
  transition: 0.2s ease-out;
  transition-property: transform, opacity;
  width: 12px;

  &.show {
    opacity: 1;
    transform: scale(1);
  }
}

.container.open {
  .notificationDot {
    opacity: 0;
    transform: scale(0);
  }
}
