@use "@sequoiacap/client-ui/styles/tokens";
@use "@sequoiacap/client-ui/styles/breakpoints";
@use "@sequoiacap/client-ui/styles/utils";

.base {
  background-color: tokens.$color-s-pastel-yellow;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 100; // Above the header in builders, but below the modal
}

.container {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
  max-width: var(--page-max-width);
  min-width: var(--page-min-width);
  padding: 12px var(--h-page-spacing);

  @include breakpoints.at-least("lg") {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding: 20px var(--h-page-spacing);
  }
}

.title {
  align-items: center;
  display: flex;
  gap: 8px;

  svg {
    flex-shrink: 0;
    height: 20px;
    width: 20px;
  }
}

.listContainer {
  align-items: center;
  display: flex;
  gap: 24px;
  grid-column: 2 / -1;
}

.list {
  align-items: center;
  display: flex;
  gap: 24px;
  list-style-type: none;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  padding: 0;
}

.item {
  --border-color: transparent;

  align-items: center;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px var(--border-color);
  color: var(--text-normal);
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  width: 24px;

  @include utils.hoverable;

  &.active {
    --border-color: #{tokens.$color-s-dark-yellow};

    color: tokens.$color-s-dark-yellow;
  }

  &.disabled {
    opacity: 0.3;
  }

  &.active,
  &.disabled {
    &:hover {
      background-color: transparent;
    }
  }

  &.icon {
    --border-color: var(--text-normal);

    svg {
      height: 14px;
      width: 14px;

      path {
        stroke: currentColor;
        stroke-width: 0.5px;
      }
    }
  }
}
